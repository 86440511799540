<template>
  <div class="app-container">
    <div class="success-icon">
      <van-icon name="success" />
    </div>
    <div class="tips">
      操作完成，客户经理会尽快联系您确认身份
    </div>
  </div>
</template>

<script>
import { Icon as VanIcon } from 'vant'
export default {
  name: 'YtServiceCost',
  components: {
    VanIcon
  }
}
</script>

<style lang="less" scoped>
.app-container {
  text-align: left;
}
.tips {
  text-align: center;
}
.success-icon {
  background: #07c160;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px auto;
}
</style>
